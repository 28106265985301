<template>
  <div-box>
    <div-height height='36vw' />
    <div-top height="36vw" class="bg">
      <div-card radius="0" padding='0vw'>
        <div-padding padding="3vw 0">
          <div-text color='#fff' bold center size='4vw'>直推列表</div-text>
        </div-padding>
        <div-height height='6vw' />
        <div-tab tab='block'>
          <van-tabs v-model='active' color='#269cfe' @click="up_down()">
            <van-tab>
              <template #title>
                <div-padding>
                  <div-img width='6vw' height='6vw'>
                    <img :src="require(`@/assets/imgs/pthy.png`)" alt="" v-if="active == 0">
                    <img :src="require(`@/assets/imgs/icon_57@2x.png`)" alt="" v-else>
                  </div-img>
                </div-padding>
              </template>
            </van-tab>
            <van-tab v-for="(item, index) in 6" :key="index">
              <template #title>
                <div-padding>
                  <div-img width='6vw' height='6vw'>
                    <img :src="require(`@/assets/imgs/av${index+1}.png`)" v-if="active == (index+1)">
                    <img :src="require(`@/assets/imgs/v${index+1}.png`)" v-else>
                  </div-img>
                </div-padding>
              </template>
            </van-tab>
          </van-tabs>
        </div-tab>
      </div-card>
    </div-top>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :noToken="false" url="/app/member/teamList" :data="{level:active}">
      <div-padding padding="4vw">
        <div-card margin="0 0 4vw 0" bg="#FFFFFF" padding="4vw" v-for="(item,index) in list" :key="index">
          <div-flex left top>
            <div-img width='13vw' height='13vw' radius="50%">
              <img :src='httpPath+item.head_img' v-if="item.head_img" />
              <img src='~@/assets/img/head@2x.png' v-else />
            </div-img>
            <div-width width='2vw' />
            <div-flex column>
              <div-height height='1vw' />
              <div-flex left>
                <div-text color='#000' bold size='4vw'>ID:{{item.id}}</div-text>
              </div-flex>
              <div-height height='0vw' />
              <div-flex left>
                <div-text color='#8A8A8A' one size='4vw'>钱包地址：{{addressFun(item.phone)}}</div-text>
                <div-width width='2vw' />
                <div-img width='3vw' height='3vw' @click.native="copyData(item.phone)">
                  <img src='~@/assets/imgs/copy@2x.png' />
                </div-img>
              </div-flex>
            </div-flex>
          </div-flex>
          <div-height height='4vw' />
          <div-card bg='#FBFBFD' padding='2vw 0'>
            <div-flex>
              <div-card width="41vw" padding='0vw'>
                <div-text color='#333' center bold size='4.5vw'>{{item.level_name}}</div-text>
                <div-text color='#999999' center size='4vw'>等级</div-text>
              </div-card>
              <div-card width="41vw" padding='0vw'>
                <div-text color='#333' center bold size='4.5vw'>{{item.team_num}}</div-text>
                <div-text color='#999999' center size='4vw'>团队人数</div-text>
              </div-card>

            </div-flex>
          </div-card>
        </div-card>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      info: {},
      list: [],
    }
  },
  created() {
    this.up_down()
  },
  methods: {},
}
</script>
<style scoped lang="less">
.bg {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // padding: 0 30rpx;
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  z-index: 10;
  background: linear-gradient(102deg, #59c079 0%, #4ca791 100%);
}
.card {
  width: 44vw;
  height: 21vw;
  border: 1px solid #3a3748;
  background-color: #282538;
  padding: 4vw 3vw;
  border-radius: 2vw;
  background-image: url("~@/assets/img/yuansu@2x.png");
  background-repeat: no-repeat;
  background-size: 17vw 13vw;
  background-position: right 0vw bottom 0vw;
}
.list {
  width: 16vw;
  height: 18vw;
  background-image: url("~@/assets/imgs/1111.png");
  background-size: 16vw 18vw;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  .item {
    width: 16vw;
    height: 18vw;
    padding: 6vw 5vw;
    img {
      width: 6vw;
      height: 6vw;
    }
  }
  &.active {
    .item {
      width: 16vw;
      height: 21vw !important;
      position: absolute;
      border-radius: 1.5vw !important;
      overflow: hidden;
      background-image: url("~@/assets/imgs/2222.png");
      background-repeat: no-repeat;
      background-size: 16vw 20.5vw;
      padding: 6vw 5vw;
    }
  }
}
</style>
