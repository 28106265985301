<template>
  <div-box bg="#F7F9FA">
    <nav-bar :title="$t('公告')" :leftArrow="true" />
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :noToken="false" url="/app/notice/noticeList" :data="{}">
      <div-padding padding="4vw">
        <div v-for="(item,index) in list" :key="index" @click="router('notice_details',{id:item.id})">

          <div-card margin="0 0 4vw 0" bg="#fff" padding="4vw">
            <div-flex left top>
              <div-img width='7vw' height='7vw'>
                <img src='~@/assets/imgs/icon_13@2x.png' />
              </div-img>
              <div-width width='3vw' />
              <div-flex column width="74vw">
                <div-text color='#000' bold one size='4vw'>{{item.title}}</div-text>
                <div-height height='2vw' />
                <div-text color='#686868' two size='3vw'>{{item.small_text}}</div-text>
              </div-flex>
            </div-flex>
            <van-divider :style="{ margin:'4vw 0' }" />
            <div-flex>

              <div-text color='#A6A6A6' size='3.5vw'>{{item.create_time}}</div-text> <div-text color='#222222' size='4vw'>查看详情</div-text>
            </div-flex>

          </div-card>

        </div>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  methods: {},
  created() {
    this.up_down()
  },
}
</script>

<style>
</style>