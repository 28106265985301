<template>
  <div class='loginbox'>
    <div class="frombox">
      <van-field clearable v-model="tell" maxlength="11" type="tel" label="手机号" placeholder="请输入您的手机号" :border='false' />
      <van-field clearable v-model="password" type="password" label="登录密码" placeholder="请输入您的登录密码" :border='false' />
      <div class="passbox">
        <router-link to="password">忘记密码</router-link>
        <router-link to="register">立即注册</router-link>
      </div>
      <van-button round type="primary" color='#451BFF' block @click="loginBtn">登录</van-button>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tell: "13186387623",
      password: "admin123",
      logo: "",
    }
  },
  computed: {},
  watch: {},
  methods: {
    loginBtn() {
      if (!this.tell) return this.$toast("请输入手机号")
      if (!this.password) return this.$toast("请输入登录密码")
      this.$post({
        url: "/app/member/login",
        loading: true,
        noToken: true,
        data: {
          phone: this.tell,
          password: this.password,
        },
        success: (res) => {
          this.local.set("token", res.data)
          this.toast({
            message: "登录成功",
            onClose: () => {
              this.goReplace("/")
            },
          })
        },
        tip: () => { },
      })
    },
  },
  created() { },
  mounted() { },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .frombox {
    width: 100%;
    /deep/.van-cell {
      margin-bottom: 15px;
      border-radius: 25px;
      background: rgba(210, 210, 210, 0.4);
      .van-field__label {
        text-align: center;
      }
    }
    /deep/.van-button {
      margin-top: 40px;
    }
    .passbox {
      width: 100%;
      padding: 5px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >a {
        color: @theme;
        font-size: 14px;
      }
    }
  }
}
</style>
