<script >
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>

<template>
  <div-flex left>
    <div-width width='0.5vw'>
      <div-height height='4vw' bg="#E72626" />
    </div-width>
    <div-width width='2vw' />
    <div-text color='#333' size='4vw' bold>{{title}}</div-text>
  </div-flex>
</template>

<style scoped>
</style>