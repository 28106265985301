<template>
  <div class="container">
    <nav-bar title="用户协议" left-arrow />
    <div class="htmlcontentbox content" v-html="list" v-if='list'></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: `左手交易手续费，右手项目上币费，在疯狂增长的数字货币市场，交易所毋容置疑是最大赢家，许多创业者对其趋之若鹜。同时，黑客针对交易所的安全攻击越来越多，监管所带来的不确定性也越来越大。
     左手交易手续费，右手项目上币费，在疯狂增长的数字货币市场，交易所毋容置疑是最大赢家，许多创业者对其趋之若鹜。同时，黑客针对交易所的安全攻击越来越多，监管所带来的不确定性也越来越大。`,
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$post({
        loading: true,
        url: "/app/article/new_article",
        success: (res) => {
          this.list = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style scoped lang="less">
.container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 0 15px;
  .content {
    width: 100%;
    background: rgba(0, 87, 255, 0.5);
    border-radius: 10px;
    padding: 10px 14px;
    color: #fff;
    font-size: 14px;
  }
}
</style>
