<template>
  <div class="passwordbox">
    <nav-bar title="忘记密码" left-arrow />
    <van-field v-model="tell" type="tel" maxlength="11" clearable label="手机号" placeholder="请输入手机账号" />
    <van-field v-model="code" type="text" maxlength="11" clearable label="验证码" placeholder="请输入验证码">
      <template #button>
        <send-code api="/app/auth/sendSms" :send-tell="{ 'phone': tell }" :send-scene="{ 'scene': 'forget_password' }" :no-token="true" color="#0057FF" round>
        </send-code>
      </template>
    </van-field>
    <van-field v-model="pass" clearable maxlength="12" type="password" placeholder="请输入登录密码" label="新密码" />
    <van-field v-model="passto" clearable maxlength="12" type="password" placeholder="请输入确认登录密码" label="重复密码" />
    <div class="btnbox">
      <van-button type="default" block @click="registBtn" round color="#0057FF" class="anBtn">确定</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tell: "",
      code: "",
      pass: "",
      passto: "",
    }
  },
  methods: {
    registBtn() {
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell)) return this.$toast("请输入正确格式的手机号")
      if (!this.code) return this.$toast("请输入验证码")
      if (!this.pass) return this.$toast("请输入登录密码")
      if (!this.passto) return this.$toast("请输入确认登录密码")
      if (this.pass !== this.passto) return this.$toast("两次输入的登录密码不一致")
      this.$post({
        url: "/app/login/forgetLoginPassword",
        loading: true,
        noToken: true,
        data: {
          mobile: this.tell,
          sms_code: this.code,
          password: this.pass,
          confirm_password: this.passto,
        },
        success: () => {
          this.toast({
            message: "修改成功",
            onClose: () => {
              this.$router.replace("login")
            },
          })
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.passwordbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 10px;
  /deep/.van-cell {
    padding: 10px 15px;
    .van-field__label {
      text-align: center;
    }
  }
  .btnbox {
    width: 100%;
    padding: 50px 15px 10px;
  }
}
</style>
