<template>
  <div-box>
    <nav-bar :title="$t('充值记录')" :leftArrow="true" />
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :noToken="false" url="/app/recharge/pageList" :data="{}">
      <div-padding padding="4vw">
        <div-card radius="3vw 3vw 0 0" bg='#fff' padding='4vw 0 1vw'>
          <div-flex left>
            <div-card margin="0 3vw 0 0" radius="0 3vw 3vw 0" bg='#53B386' width="1vw" padding='2.5vw 0vw'>
            </div-card>
            <div-text color='#000' bold size='4vw'>充值记录</div-text>
          </div-flex>
        </div-card>
        <div-card bg='#fff' radius="0" padding='3vw 4vw 0 4vw' v-for="(item,index) in list" :key="index">
          <div-flex>
            <div-flex width='100%' column height="12vw">
              <div-flex>
                <div-text color='#000' bold size='4vw'>{{$t('充值')}}</div-text>
                <div-text color='#000' bold size='4.5vw'>+{{item.amount}}</div-text>
              </div-flex>
              <div-flex>
                <div-text color='#8A8A8A' size='3vw'>{{item.create_time}}</div-text>
                <div-text color='#53B386' size='3vw' v-if="item.status==1">到账中</div-text>
                <div-text color='#FF6600' size='3vw' v-if="item.status==2">已到账</div-text>
                <div-text color='#8A8A8A' size='3vw' v-if="item.status==3">到账失败</div-text>
              </div-flex>
            </div-flex>
          </div-flex>
          <div-height height='3vw' />
          <van-divider :style="{ margin:'0 0' }" />
        </div-card>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  methods: {},
  created() {
    this.up_down()
  },
}
</script>

<style>
</style>