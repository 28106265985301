<template>
  <div-box>
    <div-top height="61vw">
      <div-card :src="require('@/assets/imgs/icon_51@2x.png')" radius="0" padding='0vw'>
        <nav-bar :title="`GAN${$t('钱包')}`" :fixed="false" leftColor="#ffffff" titleColor="#ffffff" bgColor="#00000000"
          :leftArrow="true" />
        <div-padding padding="4vw">
          <div-card bg='linear-gradient(92deg, #C7F0DD 0%, #F2FFF9 50%, #C7F0DD 100%)' padding='5vw'>
            <div-text color='#53B386' bold center size='7vw'>{{ usdt }}</div-text>
            <div-text color='#000' center size='3.5vw'>GAN余额</div-text>
          </div-card>
        </div-padding> <div-card radius="4vw 4vw 0 0" bg='linear-gradient(180deg, #CEF0E0 0%, #F7F9FA 100%)'
          padding='5vw 0 4vw'>
          <div-flex>
            <div-width width='49vw' @click.native="router('lightning', { type: 2 })">
              <div-flex center>
                <div-img width='5vw' height='5vw'>
                  <img src='~@/assets/imgs/icon_52@2x.png' />
                </div-img>
                <div-width width='2vw' />
                <div-text color='#000' size='4vw'>闪兑</div-text>
              </div-flex>
            </div-width>
            <div-width width='1px'>
              <div-height height='3vw' bg="#D2D5E5" />
            </div-width>
            <div-width width='49vw' @click.native="router('rotation', { type: 3 })">
              <div-flex center>
                <div-img width='5vw' height='5vw'>
                  <img src='~@/assets/imgs/icon_53@2x.png' />
                </div-img>
                <div-width width='2vw' />
                <div-text color='#000' size='4vw'>互转</div-text>
              </div-flex>
            </div-width>
          </div-flex>
        </div-card>
      </div-card>
    </div-top>

    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :noToken="false"
      url="/app/balance_log/balanceList" :data="{ balance_type: 3 }">
      <div-card padding="0vw 4vw" bg="#F7F9FA" radius="0" class="min">
        <div-card radius="3vw 3vw 0 0" bg='#fff' padding='4vw 0 1vw'>
          <div-flex left>
            <div-card margin="0 3vw 0 0" radius="0 3vw 3vw 0" bg='#53B386' width="1vw" padding='2.5vw 0vw'>
            </div-card>
            <div-text color='#000' bold size='4vw'>钱包明细</div-text>
          </div-flex>
        </div-card>
        <div-card bg='#fff' radius="0" padding='4vw 4vw 0 4vw' v-for="(item, index) in list" :key="index">
          <div-flex>
            <div-width width='50vw'>
              <div-flex width='100%' column height="12vw">
                <div-text color='#000' bold size='4vw'>{{ item.remark }}</div-text>
                <div-text color='#8A8A8A' size='3.5vw'>{{ item.create_time }}</div-text>
              </div-flex>
            </div-width>
            <div-flex right baseline>
              <div-text color='#000' bold size='5vw'>{{ item.amount }}</div-text>
            </div-flex>
          </div-flex>
          <div-height height='4vw' />
          <van-divider :style="{ margin: '0 0' }" />
        </div-card>
      </div-card>
    </vant-up-down>
  </div-box>
</template>

<script>
  export default {
    data() {
      return {
        list: [],
        usdt: 0,
      }
    },
    methods: {
      get_info() {
        this.$post({
          url: "/app/member/memberInfo",
          success: (res) => {
            this.usdt = res.data.coin
          },
          tip: () => { },
        })
      },
    },
    created() {
      this.get_info()
      this.up_down()
    },
  }
</script>

<style scoped lang="less">
  .min {
    min-height: 50vw;
  }
  // 空空如也
  .van-empty {
    padding-top: calc((100vh - 120vw) / 2);
    padding-bottom: calc((100vh - 120vw) / 2);
  }
  //这段样式是改了bug 和修改了下拉样式。
  //下拉刷新样式
  /deep/.van-pull-refresh {
    min-height: calc(100vh - 61vw) !important;
    // background: #ebebeb;
  }
</style>