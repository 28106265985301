<template>
  <div-box bg="#fff">
    <nav-bar :title="$t('公告详情')" :leftArrow="true" />
    <div-padding padding="2vw 4vw">
      <div-text color='#000' bold size='6vw'>{{info.title}}</div-text>
      <div-height height='2vw' />
      <div-text color='#A6A6A6' size='3.5vw'>{{info.created_at}}</div-text>
      <div-height height='2vw' />
      <div-html :html="info.content" />
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$post({
        url: "/app/notice/info",
        data: {
          id: this.$route.query.id,
        },
        success: (res) => {
          this.info = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style>
</style>