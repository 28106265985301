import { render, staticRenderFns } from "./div-title.vue?vue&type=template&id=2cc8f0e7&scoped=true"
import script from "./div-title.vue?vue&type=script&lang=js"
export * from "./div-title.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc8f0e7",
  null
  
)

export default component.exports