<template>
  <div-box>
    <div-card bg='linear-gradient(102deg, #59C079 0%, #4CA791 100%)' radius="0" padding='0vw'>
      <div-padding padding="3vw 3vw 5vw 3vw">
        <div-text color='#fff' center bold size='4vw'>我的</div-text>
      </div-padding>
      <div-card bg='#F7F9FA' radius="5vw 5vw 0 0" padding='4vw'>
        <div-flex left>
          <van-uploader :after-read="afterRead">
            <template #default>
              <div-img width='15vw' height='15vw' radius="50%">
                <img :src='httpPath + info.head_img' v-if="info.head_img" />
                <img src='~@/assets/img/head@2x.png' v-else />
                <div-son right="0" left="0" bottom="0">
                  <div-card margin="0 1vw" bg='linear-gradient(90deg, #59C079 0%, #4CA791 100%)' padding='0vw 1.5vw'>
                    <div-flex center>
                      <div-img width='3vw' height='3vw'>
                        <img src='~@/assets/imgs/icon_41@2x.png' />
                      </div-img>
                      <div-width width='1vw' />
                      <div-text color='#fff' size='3vw'>{{ info.level_name }}</div-text>
                    </div-flex>

                  </div-card>
                </div-son>
              </div-img>
            </template>
          </van-uploader>
          <div-width width='3vw' />
          <div>
            <div-flex left>
              <div-text color='#333' one size='5vw'>{{ addressFun(info.phone) }}</div-text>
              <div-width width='2vw' />
              <div-img width='3vw' height='3vw' @click.native="copyData(info.phone)">
                <img src='~@/assets/imgs/copy@2x.png' />
              </div-img>
            </div-flex>
            <div-flex left>
              <div-text color='#8A8A8A' size='4vw'>ID：{{ info.id }}</div-text>
            </div-flex>
          </div>

        </div-flex>
      </div-card>
    </div-card>
    <div-padding padding="0vw 4vw">
      <div-card :src="require('@/assets/imgs/icon_42@2x.png')" width="92vw" height="43vw" padding='5vw 0 5vw 7vw'>
        <div-flex>
          <div-card width="42vw" padding='0vw' @click.native="router('mother_coin')">
            <div-text color='#fff' bold size='6.5vw'>{{ info.point }}</div-text>
            <div-flex left>
              <div-text color='#fff' size='4vw'>平台积分</div-text>
              <van-icon color='#fff' size="4vw" name='arrow' />
            </div-flex>
          </div-card>
          <div-card width="42vw" padding='0vw'>
            <div-text color='#fff' bold size='6.5vw'>{{ teamPoint }}</div-text>
            <div-flex left>
              <div-text color='#fff' size='4vw'>团队积分</div-text>
            </div-flex>
          </div-card>
        </div-flex>
        <div-height height='3vw' />
        <div-flex>
          <div-card width="42vw" padding='0vw' @click.native="router('usdt_coin')">
            <div-text color='#fff' bold size='5vw'>{{ info.balance }}</div-text>
            <div-flex left>
              <div-text color='#fff' size='4vw'>DOGE{{ $t('钱包') }}</div-text>
              <van-icon color='#fff' size="4vw" name='arrow' />
            </div-flex>
          </div-card>
          <div-card width="42vw" padding='0vw' @click.native="router('mother_coin_s')">
            <div-text color='#fff' bold size='5vw'>{{ info.coin }}</div-text>
            <div-flex left>
              <div-text color='#fff' size='4vw'>GAN{{ $t('钱包') }}</div-text>
              <van-icon color='#fff' size="4vw" name='arrow' />
            </div-flex>
          </div-card>
        </div-flex>
      </div-card>
      <div-height height='4vw' />
      <div-card bg='#fff' padding='0 3vw'>
        <div-padding padding="4vw 0" @click.native="router('spell_list')">
          <div-flex>
            <div-flex left>
              <div-img width='5vw' height='5vw' radius="0vw">
                <img :src='require(`@/assets/imgs/icon_44@2x.png`)' />
              </div-img>
              <div-width width='4vw' />
              <div-text color='#3D3E40' size='4vw'>拼团记录</div-text>
            </div-flex>
            <van-icon color='#B1B4BA' name='arrow' />
          </div-flex>
        </div-padding>
        <div-padding padding="4vw 0" @click.native="router('system')">
          <div-flex>
            <div-flex left>
              <div-img width='5vw' height='5vw'>
                <img :src='require(`@/assets/imgs/icon_45@2x.png`)' />
              </div-img>
              <div-width width='4vw' />
              <div-text color='#3D3E40' size='4vw'>制度说明</div-text>
            </div-flex>
            <van-icon color='#B1B4BA' name='arrow' />
          </div-flex>
        </div-padding>
        <div-padding padding="4vw 0" @click.native="show = true">
          <div-flex>
            <div-flex left>
              <div-img width='5vw' height='5vw'>
                <img :src='require(`@/assets/imgs/icon_46@2x.png`)' />
              </div-img>
              <div-width width='4vw' />
              <div-text color='#3D3E40' size='4vw'>邀请好友</div-text>
            </div-flex>
            <van-icon color='#B1B4BA' name='arrow' />
          </div-flex>
        </div-padding>
        <div-padding padding="4vw 0">
          <div-flex>
            <div-flex left>
              <div-img width='5vw' height='5vw'>
                <img :src='require(`@/assets/imgs/icon_47@2x.png`)' />
              </div-img>
              <div-width width='4vw' />
              <div-text color='#3D3E40' size='4vw'>{{ $t('语言切换') }}</div-text>
            </div-flex>
            <van-switch v-model="checked" size="24px" active-color="#fff" inactive-color="#fff" @click="change" />
          </div-flex>
        </div-padding>
      </div-card>
    </div-padding>

    <van-popup v-model="show">
      <div-son top="-16vw" right="3vw">
        <div-img width='36vw' height='36vw'>
          <img src='~@/assets/imgs/icon_54@2x.png' />
        </div-img>
      </div-son>
      <div-card bg='#5EB797' width="92vw" padding='5vw 3vw 3vw 3vw'>
        <div-flex column>
          <div-text color='#fff' bold size='5vw'>邀请好友一起来玩</div-text>
          <div-height height='2vw' />
          <div-flex left>
            <div-card bg='#77C5AB' margin="0 3vw 0 0" radius="5vw" padding='1vw 3vw'>
              <div-text color='#fff' line="1" center size='3.5vw'>透明</div-text>
            </div-card>
            <div-card bg='#77C5AB' margin="0 3vw 0 0" radius="5vw" padding='1vw 3vw'>
              <div-text color='#fff' line="1" center size='3.5vw'>可信</div-text>
            </div-card>
            <div-card bg='#77C5AB' margin="0 3vw 0 0" radius="5vw" padding='1vw 3vw'>
              <div-text color='#fff' line="1" center size='3.5vw'>开放</div-text>
            </div-card>
          </div-flex>
        </div-flex>
        <div-height height='2vw' />
        <div-card bg='#E2F3ED' padding='5vw 5vw 5vw'>
          <div-flex>
            <div>
              <div-text color='#1D1D1D' size='3vw'>我的邀请链接：</div-text>
              <div-height height='2vw' />
              <div-flex left>
                <div-img width='5vw' height='5vw'>
                  <img src='~@/assets/imgs/icon_55@2x.png' />
                </div-img>
                <div-width width='1vw' />
                <div-width width='50vw'>
                  <div-text color='#000' bold size='4vw'>{{ path }}</div-text>
                </div-width>

              </div-flex>
              <div-height height='2vw' />
              <div-text color='#1D1D1D' size='3.5vw'>发送链接给好友即可接受邀请</div-text>
            </div>
            <div-img width='18vw' height='18vw'>
              <img src='~@/assets/imgs/icon_56@2x.png' />
            </div-img>
          </div-flex>
          <div-height height='4vw' />
          <div-btn radius="2vw" bg="#000" @click.native="copyData(path)">
            <div-text color='#fff' bold size='4vw'>复制链接</div-text>
          </div-btn>
        </div-card>
      </div-card>
    </van-popup>
    <div-height height='30vw' />
  </div-box>
</template>
<script>
  export default {
    data() {
      return {
        show: false,
        info: {
          head_img: "",
        },
        checked: this.getLang() == "zh" ? false : true,
        invite_code: "",
        path: "",
        teamPoint: "",
      }
    },
    created() {
      this.getinfo()
      this.get_teamPoint()
      this.get_url()
    },
    methods: {
      afterRead(file) {
        this.$toast.loading({
          message: this.$t("上传中") + "...",
          forbidClick: true,
          duration: 0,
        })
        // 上传图片
        let formData = new FormData()
        formData.append("image", file.file)
        this.$post({
          url: "/app/member/editHead",
          data: formData,
          upload: true,
          success: (res) => {
            this.$toast({ message: this.$t("上传成功！") })
            this.getinfo()
          },
          tip: () => { },
        })
      },
      set_img(img) {
        this.$post({
          url: "/api/v1/user/edit_avatar",
          data: {
            upload_type: "server",
            url: img,
          },
          success: (res) => {
            this.info.avatar = res.data.url
          },
          tip: () => { },
        })
      },
      change() {
        if (this.getLang() == "zh") {
          this.changeLang("en")
        } else {
          this.changeLang("zh")
        }
        this.getinfo()
      },
      get_url() {
        this.$post({
          url: "/app/member/share",
          success: (res) => {
            this.invite_code = res.data.invite_code
            //获取当前页面的url
            let urls = window.location.href
            //把mine改成index
            urls = urls.replace("mine", "")
            //拼接邀请码
            this.path = urls + "?code=" + this.invite_code
          },
          tip: () => { },
        })
      },
      getinfo() {
        this.$post({
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        })
      },
      get_teamPoint() {
        this.$post({
          url: "/app/member/teamPoint",
          success: (res) => {
            this.teamPoint = res.data.point
          },
          tip: () => { },
        })
      },
    },
  }
</script>
<style scoped lang="less">
  /deep/.van-popup {
    overflow-y: visible !important; // 解决弹窗滚动问题
    background-color: transparent !important;
  }
  .van-switch::before {
    content: "汉英";
    font-size: 14px;
    line-height: 1;
    color: #76708e;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    // 字间距
    letter-spacing: 2vw;
    font-weight: 550;
  }
  /deep/.van-switch__node {
    background-color: #53b386 !important;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 1px;
    left: 1px;
  }
</style>
