import { render, staticRenderFns } from "./div-card.vue?vue&type=template&id=e6d260ee&scoped=true"
import script from "./div-card.vue?vue&type=script&lang=js"
export * from "./div-card.vue?vue&type=script&lang=js"
import style0 from "./div-card.vue?vue&type=style&index=0&id=e6d260ee&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6d260ee",
  null
  
)

export default component.exports