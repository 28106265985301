var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-text",class:{ center: _vm.center, left: _vm.left, right: _vm.right, one: _vm.one,two: _vm.two, three: _vm.three, bold: _vm.bold, del: _vm.del, shrink: _vm.shrink },style:([
_vm.padding ? { padding: _vm.padding } : '',
_vm.marginRight? { marginRight: _vm.marginRight } : '',
_vm.marginLeft? { marginLeft: _vm.marginLeft } : '',
_vm.size ? { fontSize: _vm.size } : '',
_vm.color ? { color: _vm.color } : '',
_vm.line? { lineHeight: _vm.line } : '',
])},[_vm._v(" "+_vm._s(_vm.$t(_vm.$slots.default[0].text ? _vm.$slots.default[0].text : ''))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }