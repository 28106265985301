<template>
  <div class="div-text" :style="[
padding ? { padding: padding } : '',
marginRight? { marginRight: marginRight } : '',
marginLeft? { marginLeft: marginLeft } : '',
size ? { fontSize: size } : '',
color ? { color: color } : '',
line? { lineHeight: line } : '',
]" :class="{ center, left, right, one,two, three, bold, del, shrink }">
    {{ $t($slots.default[0].text ? $slots.default[0].text : '') }}
  </div>
</template>  
<script>
export default {
  name: "div-text",
  props: {
    marginLeft: {
      type: String,
      default: "",
    },
    marginRight: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "4vw",
    },
    center: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#333",
    },
    line: {
      type: String,
      default: "",
    },
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    del: {
      type: Boolean,
      default: false,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() {},
}
</script>
<style lang='less' scoped>
.div-text {
  color: #333;
  flex-shrink: 0;
}

.del {
  text-decoration: line-through;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}
.line {
  line-height: 1;
}
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.one {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  //   font-weight: normal;
  // word-wrap: break-word;
  word-break: break-all;
}

.three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  //   font-weight: normal;
  // word-wrap: break-word;
  word-break: break-all;
}

.shrink {
  flex-shrink: 0;
}
</style>
