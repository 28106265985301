<template>
  <div class="abox">
    <div-box :src="require(`@/assets/img/bj@${$i18n.locale=='zh'?'2':'1'}x.png`)">
      <nav-bar title="" bgColor="transparent" :leftArrow="true" />
      <div-foot>
        <div-padding padding="6vw">
          <div-card bg="#fff" height="81vw" radius="6vw" padding='10vw 0 0 5vw'>
            <div-flex>
              <div-text color='#333' bold size='9vw'>分享链接</div-text>
              <div-img width='38vw' height='15vw'>
                <img src='~@/assets/img/yss@2x.png' />
              </div-img>
            </div-flex>
            <div-height height='5vw' />
            <div-text color='#333' size='5vw'>分享给热爱DAPP的朋友</div-text>
            <div-height height='2vw' />
            <div-text color='#4E43BE' bold size='5vw'>心之所向 · 共建火龙生态</div-text>
            <div-height height='8vw' />
            <div-flex left>
              <div-card radius="0" bg='#E8E8E8' width="54vw" height="10vw" padding='2vw 3vw'>
                <div-text color='#000' bold one size='4vw'>{{urls}}</div-text>
              </div-card>
              <div-width width='3vw' />
              <div-card radius="0" bg='#4E43BE' padding='2vw 4vw' @click="copyData(path)">
                <div-text color='#fff' bold size='4vw'>复制</div-text>
              </div-card>
            </div-flex>
          </div-card>
        </div-padding>
      </div-foot>
    </div-box>
  </div>

</template>

<script>
export default {
  data() {
    return {
      invite_code: "",
      path: "",
      urls: "",
    }
  },
  methods: {
    get_info() {
      this.$post({
        url: "/api/v1/user/info",
        success: (res) => {
          this.invite_code = res.data.invite_code
          //获取当前页面的url
          this.urls = window.location.href
          //拼接邀请码
          this.path = this.urls + "?code=" + this.invite_code
        },
        tip: () => {},
      })
    },
  },
  created() {
    this.get_info()
  },
}
</script>

<style>
.abox {
  height: 100vh;
}
</style>