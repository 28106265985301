<template>
  <van-nav-bar clickable left-arrow safe-area-inset-top :border="false" :title="title" placeholder @click-left="onClickLeft" />

</template>
<script >
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
.van-nav-bar {
  --van-nav-bar-icon-color: #fff;
  --van-nav-bar-title-text-color: #fff;
  --van-nav-bar-background: transparent !important;
}
</style>
