var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-card",class:{fill: _vm.fill,hidden: _vm.hidden,blur: _vm.blur},style:([	_vm.bg?{background:_vm.bg}:'',
    _vm.bgcolor?{backgroundColor:_vm.bgcolor}:'',
			_vm.src?{backgroundImage:`url(${_vm.src})`}:'',
			_vm.radius?{borderRadius:_vm.radius}:'',
			_vm.border?{border:_vm.border}:'',
			_vm.shadow?{boxShadow:'0px 1vw 3vw '+_vm.shadow}:'',
			_vm.height?{height: _vm.height}:'',
			_vm.width?{width: _vm.width}:'',
      _vm.minWidth?{minWidth: _vm.minWidth}:'',
      _vm.maxWidth?{maxWidth: _vm.maxWidth}:'',
      
      _vm.padding?{padding:_vm.padding}:'',
			_vm.margin?{margin:_vm.margin}:'',
      _vm.marginRight? { marginRight: _vm.marginRight } : '',
			
		]),on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }