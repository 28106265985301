<template>
  <div class='div-tab' :class="tab">
    <slot></slot>
  </div>
</template>
<script >
export default {
  props: {
    tab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
.line {
  /deep/.van-tab {
    font-size: 4vw;
  }
  /deep/.van-tabs__line {
    background: #5fbe72 !important; // 重写颜色
    border-radius: 0 !important; // 重写圆角
    height: 0.4vw !important; // 重写高度
    width: 10vw !important; // 重写宽度
  }
}
.noline {
  /deep/.van-tab {
    font-size: 4vw;
  }
  /deep/.van-tabs__nav {
    background: transparent !important; // 重写颜色
  }
  /deep/.van-tabs__line {
    height: 0 !important; // 重写高度
  }
}
.stick {
  .van-tab {
    align-items: flex-end;
    flex: none;
    padding: 0;
  }

  .van-tab__text {
    position: relative;
    z-index: 2;
  }
}
.left {
  .van-tab {
    // align-items: flex-end;
    flex: none;
  }
  .van-tab__text {
    position: relative;
    z-index: 2;
  }
}
.block {
  .van-tab {
    background: transparent;
    padding: 0;
  }

  /deep/.van-tabs__nav {
    background: transparent;
  }
  /deep/.van-tabs--line .van-tabs__wrap {
    height: 21vw !important;
  }
  /deep/.van-tabs__line {
    background-color: transparent !important;
    width: 16vw;
    height: 21vw !important;
    position: absolute;
    border-radius: 1.5vw !important;
    overflow: hidden;
    background-image: url("~@/assets/imgs/2222.png");
    background-repeat: no-repeat;
    background-size: 16vw 20.5vw;
    padding: 6vw 5vw;
  }
  /deep/.van-tab__text {
    position: relative;
    z-index: 3;
  }
}
</style>
