<template>
  <div class='div-input' :class="tab">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "div-input",
  components: {},
  props: {
    tab: {
      type: String,
      default: "stick",
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
.div-input {
  width: 100%;
  :deep(.van-cell) {
    padding: 0;
    border-radius: 2vw;
    background: transparent;
    align-items: center;
    input {
      border: none;
      outline: none;
    }
    .van-field__label {
      width: auto;
      display: flex;
      align-items: center;
    }
    .van-field__control {
      font-size: 4vw;
    }
    :deep(&::after) {
      right: 0;
      bottom: 0;
      left: 0;
    }
    :deep(&.van-cell--clickable:active) {
      background: transparent;
    }
  }
  :deep(.van-button--plain) {
    background: transparent;
  }
  :deep(.van-button--mini) {
    height: 6.4vw;
    padding: 0 3vw;
    font-size: 3.5vw;
  }
}
</style>
