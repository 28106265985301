module.exports = {
  '请切换链': 'Please switch the Bscscanhain',
  '未登录': 'Not logged in',
  '首页': 'Home',
  '更多': 'More',
  '金额：': 'Amount',
  '请输入金额': 'Enter amount',
  '理财区': 'Financial area',
  '立即购买': 'Buy now',
  '生态建设': 'Ecological',
  '去中心化交易所': 'Exchange',
  '最新价格：': 'Latest price：',
  '天': ' Days',
  '团队': 'Team',
  '个人': 'Mine',
  '公告': 'Announcement',
  '公告详情': 'Announcement details',
  '功能建设中': 'Under construction',
  '购买成功': 'Purchase success',
  '请选择天数': 'Please select the number of days',
  '大区业绩/USDT': 'Regional/USDT',
  '小区业绩/USDT': 'Community/USDT',
  '直推列表': 'Direct push list',
  '复制成功': 'Copy success',
  '团队人数': 'Team number',
  '团队业绩/USDT': 'Performance/USDT',
  '钱包': ' Wallet',
  '提现': 'Withdraw',
  '充值': 'Recharge',
  '其他功能': 'Other functions',
  '理财购买记录': 'Financial purchase record',
  '制度说明': 'System description',
  '语言切换': 'Language switch',
  '分享链接': 'Share Link',
  '分享给热爱DAPP的朋友': 'Share with friends who love DAPP',
  '心之所向 · 共建火龙生态': 'Heart is the way to build the fire dragon ecology',
  '复制': 'Copy',
  '可用金额': 'Available amount',
  '互转': 'Transfer',
  '闪兑': 'Exchange',
  '钱包明细': 'Wallet details',
  '互转记录': 'Transfer record',
  '钱包地址': 'Wallet address',
  '请输入钱包地址': 'Please enter the wallet address',
  '转出数量': 'Transfer amount',
  '请输入转出数量': 'Please enter the transfer amount',
  '手续费': 'Handling fee',
  '实际扣除数量': 'Actual deduction amount',
  '自动计算': 'Auto calculate',
  '确认': 'Confirm',
  '转出': 'Transfer out',
  '闪兑数量': 'Exchange amount',
  '请输入闪兑数量': 'Please enter the exchange amount',
  '实际到账': 'Actual account',
  '实际闪兑数量': 'Actual exchange amount',
  '兑换成功': 'Exchange success',
  '充值记录': 'Recharge record',
  '请输入充值金额': 'Recharge amount',
  '充值金额': 'Recharge amount',
  '充值地址': 'Recharge address',
  '提现记录': 'Withdraw record',
  '提现金额': 'Withdraw amount',
  '请输入提现金额': 'Withdrawal amount',
  '提现成功': 'Withdrawal success',
  '进行中': 'In progress',
  '已结束': 'Ended',
  '购买金额': 'Purchase amount',
  '到期时间': 'Due time',
  '购买时间': 'Purchase time',
  '每日收益': 'Daily income',
  '理财周期': 'Financial cycle',
  '空空如也': 'Empty',
  '上传成功！': 'Uploaded successfully!',
  '上传中': 'Uploading',
  '签名失败': 'Signature failed',
  '拼团': 'Group',
  '中文': 'English',
  //
  '去中心化应用': 'DAPP',
  '通知公告': 'Announ',
  '币': ' Coin',
  '走势图': 'Trend',
  '最新价格': 'New price',
  '查看详情': 'Details',
  '我的记录': 'Record',
  '我的拼团记录': 'Record',
  '参与拼团': 'Join group',
  '进入': 'Enter',
  '当前进行': 'Current',
  '期次金额': 'Period amount',
  '期次': 'Period',
  '参与金额': 'Join amount',
  '参与人数': 'Join number',
  '当前参与人数': 'Current join number',
  '拼团失败': 'Group failed',
  '剩余金额': 'Remaining amount',
  '我的': 'Mine',
  '平台积分': 'Platform points',
  '拼团记录': 'Group record',
  '邀请好友': 'Invite friends',
  '复制链接': 'Copy link',
  '发送链接给好友即可接受邀请': 'Send the link to your friends',
  '邀请好友一起来玩': 'Invite friends',
  '开放': 'Open',
  '可信': 'Trust',
  '透明': 'Trans',
  '我的邀请链接：': 'My invitation link：',
  '提现地址': 'Withdraw address',
  '提现金额(DOGE)': 'Withdraw amount(DOGE)',
  '可用余额': 'Available balance',
  '提现说明': 'Withdraw description',
  '已提交提现申请': 'Withdrawal application submitted',
  '充值金额(DOGE)': 'Recharge amount(DOGE)',
  '闪兑成功': 'Exchange success',
  '请输入闪兑金额': 'Please enter the exchange amount',
  '闪兑金额(DOGE)': 'Exchange amount(DOGE)',
  '闪兑金额(GAN)': 'Exchange amount(GAN)',
  '闪兑说明': 'Exchange description',
  '实际数量': 'Actual amount',
  '闪兑记录': 'Exchange record',
  '互转说明': 'Transfer description',
  '转出数量(GAN)': 'Transfer amount(GAN)',
  '转出数量(DOGE)': 'Transfer amount(DOGE)',
  '转出说明': 'Transfer description',
  '兑换': 'Exchange',
  '积分明细': 'Points details',
  '兑换金额(DOGE)': 'Exchange amount(DOGE)',
  '兑换说明': 'Exchange description',
  '到账数量': 'Account amount',
  '积分': 'Points',
  '积分记录': 'Points record',
  '1DOGE兑换': '1DOGE exchange',
  '个平台积分': 'Points',
  '请输入兑换数量': 'Please enter the exchange amount',
  'DOGE余额': 'DOGE balance',
  'GANN余额': 'GAN balance',
  '第': 'No.',
  '期': 'Period',
  '立即参与': 'Join now',
  '参与说明': 'Join description',
  '参与成功': 'Join success',
  '参与拼团价格(DOGE)': 'Join price(DOGE)',
  '参与金额不能大于': 'Join amount cannot be greater than',
  '参与金额不能小于': 'Join amount cannot be less than',
  '请输入参与金额': 'Please enter the join amount',
  '拼团成功': 'Group success',
  '未开始': 'Not started',
  '团队积分': 'Team points',
}
