<template>
  <div-box>
    <!-- <nav-bar :title="$t('首页')" /> -->
    <div-card bg='linear-gradient(102deg, #59C079 0%, #4CA791 100%)' radius="0" padding='0vw'>
      <div-padding padding="4vw">
        <div-flex>
          <div-flex left>
            <div-img width='8vw' height='8vw'>
              <img src='~@/assets/imgs/icon_2@2x.png' />
            </div-img>
            <div-width width='1vw' />
            <!-- <div-img width='24vw' height='6vw' v-if="$i18n.locale == 'zh'">
              <img src='~@/assets/imgs/icon_1@2x.png' />
            </div-img>
            <div-img width='24vw' height='6vw' v-if="$i18n.locale == 'en'">
              <img src='~@/assets/imgs/icon_1.png' />
            </div-img> -->
            <div-text color='#fff' size='5vw'>DOGE</div-text>
            <div-width width='2vw' />
            <div-text color='#fff' size='5vw'>去中心化应用</div-text>
          </div-flex>
          <div-card border='1px solid rgba(255,255,255,0.3)' bg='linear-gradient(91deg, #FFFFFF 0%, #D1FFF0 100%)'
            padding='1vw' radius="50vw">
            <div-flex left>
              <div-img width='5vw' height='5vw'>
                <img src='~@/assets/imgs/icon_3@2x.png' />
              </div-img>
              <div-width width='1vw' />
              <div-text color='#56BA7E' size='3vw' bold>中文</div-text> <div-width width='1vw' />
            </div-flex>
          </div-card>
        </div-flex>
      </div-padding>

      <div-card bg='#F7F9FA' height="38vw" radius="5vw 5vw 0 0" padding='4vw'>
        <van-swipe :autoplay='3000' indicator-color='white'>
          <van-swipe-item v-for='(item, index) in banner' :key='index'>
            <div-img width='100%' height='30vw' radius="3vw">
              <img :src='httpPath + item.image_path' />
            </div-img>
          </van-swipe-item>
        </van-swipe>
      </div-card>
    </div-card>
    <div-padding padding="0 4vw 20vw 4vw">

      <div-card padding='0' bg="#fff" radius='0vw' @click="router('notice_list')">
        <div-card :src="require('@/assets/imgs/icon_5@2x.png')" radius='0vw' padding='1.5vw 3vw'>
          <div-text color='#844710' line="1" size='4vw' bold>通知公告</div-text>
        </div-card>
        <div-notice v-if="notice">
          <div-width width='11vw' />
          <div-img width='4vw' height='4vw'>
            <img src='~@/assets/imgs/icon_6@2x.png' />
          </div-img>

          <van-swipe wrapable vertical :autoplay='3000' :show-indicators='false'>
            <van-swipe-item v-for="(item, index) in notice" :key="index">
              <div-text one center color='#000000' size='4vw'>{{ item.title }}</div-text>
            </van-swipe-item>
          </van-swipe>
          <div-img width='4vw' height='4vw'>
            <img src='~@/assets/imgs/icon_7@2x.png' />
          </div-img>
          <div-width width='11vw' />
        </div-notice>
      </div-card>

      <div-height height='4vw' />
      <div-card bg="#FFFFFF" height="105vw" padding="5vw 3vw">
        <div-flex>
          <div-flex left>
            <div-img width='15vw' height='15vw'>
              <img src='~@/assets/imgs/xcimg.png' />
            </div-img>
            <div-width width='3vw' />
            <div-flex column>
              <div-text color='#000000' bold size='4.5vw'>GAN{{ $t('币') }}</div-text>
              <div-text color='#545F5D' bold size='3.5vw'>走势图</div-text>
            </div-flex>
          </div-flex>

          <div-flex column>
            <div-text color='#53B386' bold right size='4.5vw'>{{ mu_new_price }}DOGE</div-text>
            <div-text color='#545F5D' bold right size='3.5vw'>最新价格</div-text>
          </div-flex>
        </div-flex>
        <div id="onechar" class="onechar"></div>
      </div-card>

    </div-padding>
  </div-box>
</template>
<script>
  import Web3 from "web3"
  export default {
    data() {
      return {
        banner: [],
        notice: [],

        active: 0,
        phone: "",
        loading: false,
        address: "",
        code: "",
        token: "",
        manager_info: {
          min: 0,
          max: 0,
        },
        day: 0,
        num: "",
        mu_new_price: "",
        zi_new_price: "",
      }
    },
    created() {
      if (this.$route.query.code) {
        this.code = this.$route.query.code
      }
      if (this.session.get("token")) {
        this.token = this.session.get("token")
      }
      this.getdatas()
      this.setlogin()
    },
    mounted() { },
    methods: {
      getdatas() {
        this.$post({
          url: "/app/slider/sliderList",
          success: (res) => {
            this.banner = res.data
          },
          tip: () => { },
        })
        this.$post({
          url: "/app/notice/noticeList",
          success: (res) => {
            this.notice = res.data.data
          },
          tip: () => { },
        })
      },
      setlogin() {
        this.$nextTick(() => {
          if (!this.token) {
            this.$toast.loading({
              forbidClick: true,
            })
            var time
            time = setInterval(() => {
              this.address = this.$store.state.wallet.account
              if (this.$store.state.wallet.account != "") {
                clearInterval(time)
                this.code && console.warn("code", this.code)
                let message = this.getGuid()
                let web3 = new Web3(window.ethereum)
                web3.eth.personal.sign(
                  message,
                  this.address,
                  async (err, res) => {
                    if (res != null && res !== undefined && res !== "") {
                      this.$post({
                        url: "/app/auth/login",
                        noToken: true,
                        loading: true,
                        data: {
                          account: this.address,
                          msg: message,
                          sign: res,
                          invite_code: this.code || 123456,
                        },
                        success: (res) => {
                          console.warn("登录成功")

                          this.session.set("token", res.data.token)
                          this.getdatas()
                          this.get_one_data()
                        },
                        tip: () => { },
                      })
                    } else {
                      this.$toast.clear()
                      this.$dialog
                        .alert({
                          message: this.$t("签名失败"),
                        })
                        .then(() => {
                          this.session.del("token")
                          window.location.reload()
                        })
                    }
                  }
                )
              }
            }, 1000)
          }
          if (this.token) {
            this.get_one_data()
          }
        })
      },

      //获取母币数据
      get_one_data() {
        this.$post({
          url: "/app/price_log/priceList",
          success: (res) => {
            this.mu_new_price = res.data[0].price
            const prices = res.data.map((item) => parseFloat(item.price))
            const times = res.data.map((item) => item.date)
            prices.reverse()
            times.reverse()

            this.get_one_Charts(prices, times)
          },
          tip: () => { },
        })
      },

      // 使用柱形图，关于其他配置可以去官网查看
      get_one_Charts(prices, times) {
        var chartDom = document.getElementById("onechar")
        var myChart = this.$echarts.init(chartDom, "null", {
          width: "340px",
          height: "340px",
        })
        var option = {
          color: ["#53B485"],
          tooltip: {
            trigger: "axis", //坐标轴触发
            axisPointer: {
              //坐标轴指示器配置项。
              //坐标轴指示器配置项。
              type: "line", // 默认为直线，可选为：'line' | 'shadow'
              lineStyle: {
                //直线指示器样式设置。
                color: "#53B386",
                width: 1,
                type: "solid", //'dotted'虚线 'solid'实线
              },
              label: {
                //坐标轴指示器的文本标签。
                show: true,
                formatter: function (params) {
                  //格式化提示信息
                  return params.value
                },
                backgroundColor: "#53B386",
              },
            },
          },
          xAxis: {
            // type: "category", //类目轴
            boundaryGap: false, //坐标轴两边留白策略
            data: times, //坐标轴刻度标签的相关设置。
            axisLine: {
              show: false,
            },

            splitLine: {
              //网格线
              show: true,
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              show: false, //是否显示
              interval: 0, //强制显示所有标签
              rotate: 20, //旋转角度
              textStyle: {
                color: "#000",
              },
            },
            axisTick: {
              show: false,
            },
          },
          grid: {
            //图表的位置
            left: "0%", //图表距离左边的距离
            right: "7%",
            bottom: "10%",
            top: "15%",
            containLabel: true, //grid 区域是否包含坐标轴的刻度标签。
          },
          yAxis: {
            // type: "value", //数值轴
            // boundaryGap: false, //坐标轴两边留白策略
            data: "", //坐标轴刻度标签的相关设置。
            type: "value",
            min: function (value) {
              return value.min
            },
          },

          series: [
            {
              data: prices,
              type: "line",
              smooth: 1, //是否平滑曲线显示
              showSymbol: false, //是否显示标记点
              lineStyle: {
                color: "#53B485", // 设置线的颜色为红色
                width: 3, // 设置线的宽度
              },

              areaStyle: {
                //折线的颜色

                // 渐变色
                color: {
                  type: "linear", //线性渐变
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#55B980", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#FDFEFE", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          ],
        }

        option && myChart.setOption(option)
        // chartBox.setOption(option)
        // 根据页面大小自动响应图表大小
        window.addEventListener("resize", function () {
          option && myChart.resize()
        })
      },
    },
  }
</script>
<style scoped lang="less">
  .onechar {
    width: 100%;
    height: 100%;
    position: relative;
    top: -7vw;
  }
  .twochar {
    width: 100%;
    height: 100%;
  }
</style>
