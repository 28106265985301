module.exports = {
  '请切换链': '请切换 Bscscan Chain',
  '未登录': '未登录',
  '首页': '首页',
  '更多': '更多',
  '金额：': '金额：',
  '请输入金额': '请输入金额',
  '理财区': '理财区',
  '立即购买': '立即购买',
  '生态建设': '生态建设',
  '去中心化交易所': '去中心化交易所',
  '最新价格：': '最新价格：',
  '天': '天',
  '团队': '团队',
  '个人': '个人',
  '公告': '公告',
  '公告详情': '公告详情',
  '功能建设中': '功能建设中',
  '购买成功': '购买成功',
  '请选择天数': '请选择天数',
  '大区业绩/USDT': '大区业绩/USDT',
  '小区业绩/USDT': '小区业绩/USDT',
  '直推列表': '直推列表',
  '复制成功': '复制成功',
  '团队人数': '团队人数',
  '团队业绩/USDT': '团队业绩/USDT',
  '钱包': '钱包',
  '提现': '提现',
  '充值': '充值',
  '其他功能': '其他功能',
  '理财购买记录': '理财购买记录',
  '制度说明': '制度说明',
  '语言切换': '语言切换',
  '分享链接': '分享链接',
  '分享给热爱DAPP的朋友': '分享给热爱DAPP的朋友',
  '心之所向 · 共建火龙生态': '心之所向 · 共建火龙生态',
  '复制': '复制',
  '可用金额': '可用金额',
  '互转': '互转',
  '闪兑': '闪兑',
  '钱包明细': '钱包明细',
  '互转记录': '互转记录',
  '钱包地址': '钱包地址',
  '请输入钱包地址': '请输入钱包地址',
  '转出数量': '转出数量',
  '请输入转出数量': '请输入转出数量',
  '手续费': '手续费',
  '实际扣除数量': '实际扣除数量',
  '自动计算': '自动计算',
  '确认': '确认',
  '转出': '转出',
  '请输入闪兑数量': '请输入闪兑数量',
  '闪兑数量': '闪兑数量',
  '实际到账': '实际到账',
  '实际闪兑数量': '实际闪兑数量',
  '兑换成功': '兑换成功',
  '充值记录': '充值记录',
  '请输入充值金额': '请输入充值金额',
  '充值金额': '充值金额',
  '充值地址': '充值地址',
  '提现记录': '提现记录',
  '提现金额': '提现金额',
  '请输入提现金额': '请输入提现金额',
  '提现成功': '提现成功',
  '进行中': '进行中',
  '已结束': '已结束',
  '购买金额': '购买金额',
  '到期时间': '到期时间',
  '购买时间': '购买时间',
  '每日收益': '每日收益',
  '理财周期': '理财周期',
  '空空如也': '空空如也',
  '上传成功！': '上传成功！',
  '上传中': '上传中',
  '签名失败': '签名失败',
  '拼团': '拼团',
  '中文': '中文',
  '去中心化应用': '去中心化应用',
  '通知公告': '通知公告',
  '币': '币',
  '走势图': '走势图',
  '最新价格': '最新价格',
  '查看详情': '查看详情',
  '我的记录': '我的记录',
  '我的拼团记录': '我的拼团记录',
  '参与拼团': '参与拼团',
  '进入': '进入',
  '当前进行': '当前进行',
  '期次金额': '期次金额',
  '期次': '期次',
  '参与金额': '参与金额',
  '参与人数': '参与人数',
  '当前参与人数': '当前参与人数',
  '拼团失败': '拼团失败',
  '剩余金额': '剩余金额',
  '我的': '我的',
  '平台积分': '平台积分',
  '拼团记录': '拼团记录',
  '邀请好友': '邀请好友',
  '复制链接': '复制链接',
  '发送链接给好友即可接受邀请': '发送链接给好友即可接受邀请',
  '邀请好友一起来玩': '邀请好友一起来玩',
  '开放': '开放',
  '可信': '可信',
  '透明': '透明',
  '我的邀请链接：': '我的邀请链接：',
  '提现地址': '提现地址',
  '提现金额(DOGE)': '提现金额(DOGE)',
  '可用余额': '可用余额',
  '提现说明': '提现说明',
  '已提交提现申请': '已提交提现申请',
  '充值金额(DOGE)': '充值金额(DOGE)',
  '闪兑成功': '闪兑成功',
  '请输入闪兑金额': '请输入闪兑金额',
  '闪兑金额(DOGE)': '闪兑金额(DOGE)',
  '闪兑金额(GAN)': '闪兑金额(GAN)',
  '闪兑说明': '闪兑说明',
  '实际数量': '实际数量',
  '闪兑记录': '闪兑记录',
  '互转说明': '互转说明',
  '转出数量(GAN)': '转出数量(GAN)',
  '转出数量(DOGE)': '转出数量(DOGE)',
  '转出说明': '转出说明',
  '兑换': '兑换',
  '积分明细': '积分明细',
  '兑换金额(DOGE)': '兑换金额(DOGE)',
  '兑换说明': '兑换说明',
  '到账数量': '到账数量',
  '积分': '积分',
  '积分记录': '积分记录',
  '1DOGE兑换': '1DOGE兑换',
  '个平台积分': '个平台积分',
  '请输入兑换数量': '请输入兑换数量',
  'DOGE余额': 'DOGE余额',
  'GAN余额': 'GAN余额',
  '第': '第',
  '期': '期',
  '立即参与': '立即参与',
  '参与说明': '参与说明',
  '参与成功': '参与成功',
  '参与拼团价格(DOGE)': '参与拼团价格(DOGE)',
  '参与金额不能大于': '参与金额不能大于',
  '参与金额不能小于': '参与金额不能小于',
  '请输入参与金额': '请输入参与金额',
  '拼团成功': '拼团成功',
  '未开始': '未开始',
  '团队积分': '团队积分',
}
