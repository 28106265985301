<template>
  <div-box>
    <nav-bar :title="$t('充值')" :rightText="$t('充值记录')" @click-right="router('recharge_list')" :leftArrow="true" />
    <div-padding padding="4vw">
      <div-card margin="0 0 4vw 0" bg='#fff' padding='3vw'>
        <div-flex left>
          <div-text color='#000' bold size='4vw'>充值地址</div-text>
          <div-width width='5vw' />
          <div-input>
            <div-card padding='0vw' radius='2vw'>
              <van-field :border='false' type="textarea" rows="1" autosize readonly clearable :value="address"
                :placeholder='$t("充值地址")'>
                <template #button>
                  <div-img width='3vw' height='3vw'>
                    <img src='~@/assets/imgs/copy@2x.png' @click="copyData(address)" />
                  </div-img>
                </template>
              </van-field>
            </div-card>
          </div-input>
        </div-flex>
      </div-card>
      <div-card bg='#fff' padding='4vw' margin="0 0 4vw 0">
        <div-text color='000' bold size='4vw'>{{ $t('充值金额(DOGE)') }}</div-text>
        <div-height height='4vw' />
        <div id="field">
          <div-input>
            <van-field :border='false' type="number" clearable v-model='num' :placeholder='$t("请输入充值金额")'>
              <template #button>
                <div-text size='5vw' bold color='#0E0E0E'>DOGE</div-text>
              </template>
            </van-field>
          </div-input>
        </div>
        <van-divider :style="{ margin: '3vw 0 4vw' }" />
        <div-text color='#999999' size='4vw'>{{ $t('可用余额') }} {{ balance }}</div-text>
      </div-card>

    </div-padding>

    <div-foot padding="4vw 4vw 20vw 4vw">
      <div-btn ref="btn" radius="20vw" bg="#53B386" @click.native="submit">
        <div-text color='#fff' bold size='4vw'>确认</div-text>
      </div-btn>
    </div-foot>
  </div-box>
</template>

<script>
  export default {
    data() {
      return {
        address: "",
        num: "",
        loading: false,
        price: "",
        balance: "",
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      formatter(value) {
        // Remove any non-numeric characters
        value = value.replace(/[^0-9.]/g, "")
        // Limit to two decimal places
        const decimalIndex = value.indexOf(".")
        if (decimalIndex !== -1) {
          value = value.slice(0, decimalIndex + 5)
        }
        return value
      },
      getdata() {
        this.$post({
          url: "/app/member/memberInfo",
          success: (res) => {
            this.balance = res.data.balance
          },
          tip: () => { },
        })
        this.$post({
          url: "/app/recharge/getAddress",
          success: (res) => {
            this.address = res.data.address
          },
          tip: () => { },
        })
      },
      submit() {
        if (!this.num) return this.$toast(this.$t("请输入充值金额"))
        this.$toast.loading({
          forbidClick: true,
          duration: 0,
        })
        this.$refs.btn.disabled = true
        this.$post({
          url: "/app/recharge/rechargeDo",
          data: { amount: this.num },
          success: (res) => {
            this.transfer(res.data)
          },
          tip: () => {
            setTimeout(() => {
              this.$refs.btn.disabled = false
            }, 2000)
          },
        })
      },
      transfer(id) {
        let that = this
        that.$nextTick(() => {
          that.blockFun
            .transfer(
              "doge",
              that.num,
              that.address,
              "ether",
            )
            .then((hash) => {
              that.recharge(hash, id)
            })
            .catch((err) => {
              that.$toast.fail(err.msg)
              setTimeout(() => {
                this.$refs.btn.disabled = false
              }, 2000)
            })
        })
      },
      recharge(hash, id) {
        this.$post({
          url: "/app/recharge/rechargeSuccess",
          data: { hash, id },
          success: (res) => {
            this.num = ""
            this.$toast({
              message: "充值成功",
              onClose: () => {
                this.$refs.btn.disabled = false
              },
            })
          },
          tip: () => {
            this.num = ""
            setTimeout(() => {
              this.$refs.btn.disabled = false
            }, 2000)
          },
        })
      },
    },
  }
</script>

<style scoped lang="less">
  #field /deep/.van-field__control {
    font-size: 7vw !important;
    font-weight: 550 !important;
    color: #000 !important;
  }
</style>