<template>
  <div-box>
    <div-card radius="0" bg='linear-gradient(102deg, #59C079 0%, #4CA791 100%)' padding='0vw'>
      <div-padding padding="3vw 0 5vw 0">
        <div-text color='#fff' bold center size='4vw'>拼团</div-text>
      </div-padding>
      <div-card radius="5vw 5vw 0 0" bg='#F7F9FA' padding='5vw 3vw'>
        <div-flex>
          <div-text color='#333' bold size='4vw'>参与拼团</div-text>
          <div-card radius="10vw" bg='#D6EBE3' padding='1vw 2vw' @click="router('spell_list')">
            <div-flex left>
              <div-img width='3vw' height='3vw'>
                <img src='~@/assets/imgs/icon_15@2x.png' />
              </div-img>
              <div-width width='1vw' />
              <div-text color='#53B386' bold size='3vw'>我的记录</div-text>
            </div-flex>
          </div-card>
        </div-flex>
      </div-card>
    </div-card>
    <div-padding padding="0 4vw">
      <div-card :src="require(`@/assets/imgs/icon_${num(58+(index%3))}@2x.png`)" width="92vw" height="38vw" padding='5vw 5vw 0 5vw' margin="0 0 4vw 0" @click="router('buy_list',{id:item.id})" v-for="(item,index) in list" :key="index">
        <div-flex>
          <div-flex column>
            <div-text color='#333' style="transform: translateY(-16px);" bold size='7vw'>{{item.goods_name}}</div-text>
            <div-text color='#595A5F' style="transform: translateY(-10px);" size='4vw' v-if="item.period">{{$t('当前进行')}}: {{$t('第')}}{{item.period.period_no}}{{$t('期')}}</div-text>
          </div-flex>
          <div-flex column>
            <div-img width='19vw' height='19vw'>
              <img src='~@/assets/imgs/icon_61@2x.png' />
            </div-img>
            <div-card radius="50vw" bg='#53B386' style="transform: translateY(-16px);" padding='1vw 4vw'>
              <div-text color='#fff' center size='4vw'>进入</div-text>
            </div-card>
          </div-flex>
        </div-flex>
        <div-text color='#595A5F' style="transform: translateY(-16px);" size='4vw' v-if="item.period&&item.period.status==1">{{item.period.start_time}}–{{item.period.end_time}}</div-text>
        <div-text color='#595A5F' style="transform: translateY(-16px);" size='4vw' v-if="item.period.status==0">未开始</div-text>
        <div-text color='#595A5F' style="transform: translateY(-16px);" size='4vw' v-if="item.period.status==3">已结束</div-text>
      </div-card>
      <van-empty :description="$t('空空如也')" v-if="list && list.length == 0">
      </van-empty>
    </div-padding>
    <div-height height='15vw' />
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      info: {},
      list: [],
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    num(num) {
      //num是任意数循环输出58 59 60
      return num
    },
    get_info() {
      this.$post({
        url: "/app/game_goods/goodsList",
        success: (res) => {
          this.list = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style scoped lang="less">
</style>
