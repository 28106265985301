<template>
  <div class="loginbox">
    <div class="logobox">
      <img src="../../../assets/img/aac.png" alt="" />
    </div>
    <div class="frombox">
      <van-field clearable v-model="tell" type="tel" maxlength="11" label="手机号" placeholder="请输入您的手机号" :border="false" />
      <van-field v-model="code" type="text" maxlength="11" label="验证码" clearable placeholder="请输入验证码" :border="false">
        <template #button>
          <send-code api="/app/auth/sendSms" :send-tell="{ 'phone': tell }" :send-scene="{ 'scene': 'register' }" :no-token="true"></send-code>
        </template>
      </van-field>
      <van-field clearable v-model="pass" type="password" label="登录密码" placeholder="请输入位登录密码" :border="false" />
      <van-field clearable v-model="passto" type="password" label="确认密码" placeholder="请输入确认登录密码" :border="false" />
      <van-field clearable maxlength="6" v-model="paypass" type="password" label="交易密码" placeholder="请输入您的交易密码" :border="false" />
      <van-field clearable maxlength="6" v-model="paypassto" type="password" label="确认密码" placeholder="请输入确认交易密码" :border="false" />
      <van-field clearable v-model="replacecdoe" type="text" :readonly="disabled" label="邀请码" placeholder="请输入邀请码" :border="false" />
      <div class="tit" style="display: flex; justify-content: space-between">
        <router-link to="login">去登录</router-link>
        <router-link to="download">下载APP</router-link>
      </div>
      <div class="btnbox">
        <van-button round type="primary" color="#0057FF" block @click="(show = false)">注册</van-button>
      </div>
      <div class="tit">
        <van-checkbox v-model="checked" icon-size="16">
          <span>我已阅读并同意<span style="color: #0665e4" @click="goOther(`agreement`)">《用户协议》</span>
          </span>
        </van-checkbox>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sendData: {
        account: this.tell,
        scene: "register",
      },
      logo: "",
      tell: "",
      code: "",
      pass: "",
      passto: "",
      paypass: "",
      paypassto: "",
      replacecdoe: "",
      disabled: false,
      downpath: "",
      checked: true,
    }
  },
  computed: {},
  watch: {},
  methods: {
    goOther(url) {
      this.$router.push(url)
    },
    registBtn() {
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell)) return this.$toast("请输入正确格式的手机号")
      if (!this.code) return this.$toast("请输入验证码")

      if (!this.pass) return this.$toast("请输入登录密码")
      if (!this.passto) return this.$toast("请输入确认登录密码")
      if (this.pass !== this.passto) return this.$toast("两次输入的登录密码不一致")
      if (!this.paypass) return this.$toast("请输入交易密码")
      if (!/^\d{6}$/.test(this.paypass)) return this.$toast("交易密码为6位数字格式")
      if (!this.paypassto) return this.$toast("请输入确认交易密码")
      if (this.paypass !== this.paypassto) return this.$toast("两次输入的交易密码不一致！")
      if (!this.replacecdoe) return this.$toast("请输入邀请码")
      if (!this.checked) return this.$toast("请勾选用户注册协议")

      this.$post({
        url: "/app/login/register",
        loading: true,
        noToken: true,
        data: {
          mobile: this.tell,
          sms_code: this.code,
          password: this.pass,
          confirm_password: this.passto,
          deal_password: this.paypass,
          sec_deal_password: this.paypassto,
          invite_code: this.replacecdoe,
        },
        success: () => {
          this.toast({
            message: "注册成功",
            onClose: () => {
              if (!this.disabled) {
                this.$router.replace("login")
              } else {
                this.$router.push("download")
              }
            },
          })
        },
        tip: () => {},
      })
    },
  },
  created() {
    if (this.$route.query.code) {
      this.replacecdoe = this.$route.query.code
      this.disabled = true
    }
  },
}
</script>
<style lang="less" scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 10px 15px;
  .logobox {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 110px;
      height: 110px;
      border-radius: 10px;
    }
  }
  .frombox {
    width: 100%;
    /deep/.van-cell {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 10px;
      background: rgba(250, 250, 250, 0.8);
      .van-field__label {
        text-align: center;
      }
    }
    .btnbox {
      width: 100%;
      margin: 20px 0;
    }
    .tit {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      > a {
        color: @theme;
        font-size: 14px;
      }
    }
  }
}
</style>
