<template>
  <div class="div-img" :style="[
    src?{backgroundImage:`url(${src})`}:'',
    transform?{transform:transform}:'',
			radius?{borderRadius:radius}:'',
			border?{border:border}:'',
			height?{height}:'',
			width?{width}:'',
      padding?{padding:padding}:'',
			margin?{margin:margin}:'',

  ]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "div-img",
  props: {
    border: {
      type: String,
      default: "",
    },
    transform: {
      type: String,
      default: "",
    },
    src: {
      type: String,
    },
    height: {
      type: String,
      default: "10vw",
    },
    width: {
      type: String,
      default: "10vw",
    },
    radius: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "",
    },
    margin: {
      type: String,
      default: "",
    },
  },
}
</script>
<style lang='less' scoped>
.div-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  flex-shrink: 0;
  overflow: hidden;
  /deep/img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
