import { render, staticRenderFns } from "./spell.vue?vue&type=template&id=5e7e8a5c&scoped=true"
import script from "./spell.vue?vue&type=script&lang=js"
export * from "./spell.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7e8a5c",
  null
  
)

export default component.exports