<template>
  <nav-bar :left-arrow="left" :title="title" fixed bgColor="#fff" leftColor="#333" titleColor="#333" />
</template>
<script>
export default {
  name: "div-navber",
  props: {
    title: {
      type: String,
      default: "",
    },
    left: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
</style>
