var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-img",style:([
    _vm.src?{backgroundImage:`url(${_vm.src})`}:'',
    _vm.transform?{transform:_vm.transform}:'',
			_vm.radius?{borderRadius:_vm.radius}:'',
			_vm.border?{border:_vm.border}:'',
			_vm.height?{height: _vm.height}:'',
			_vm.width?{width: _vm.width}:'',
      _vm.padding?{padding:_vm.padding}:'',
			_vm.margin?{margin:_vm.margin}:'',

  ])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }