<template>
  <div class="div-foot" :style="
	[
		radius?{borderRadius:radius}:'',
		bg?{background:bg}:'',
		padding?{padding:padding}:'',
		shadow?{boxShadow:'0px 1vw 3vw '+shadow}:'',
	]" :class="{tabBar}">
    <slot></slot>

  </div>
</template>
<script>
export default {
  name: "div-foot",
  props: {
    shadow: {
      type: [String, Boolean],
      default: false,
    },
    radius: {
      type: String,
      default: "0",
    },
    padding: {
      type: String,
      default: "0vw",
    },
    bg: {
      type: String,
      default: "transparent",
    },
    tabBar: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang='less' scoped>
.div-foot {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // padding: 0 30rpx;
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  z-index: 100;
}
.tabBar {
}
</style>
