<template>
  <div class="div-card" @click="$emit('click')" :style="
		[	bg?{background:bg}:'',
    bgcolor?{backgroundColor:bgcolor}:'',
			src?{backgroundImage:`url(${src})`}:'',
			radius?{borderRadius:radius}:'',
			border?{border:border}:'',
			shadow?{boxShadow:'0px 1vw 3vw '+shadow}:'',
			height?{height}:'',
			width?{width}:'',
      minWidth?{minWidth}:'',
      maxWidth?{maxWidth}:'',
      
      padding?{padding:padding}:'',
			margin?{margin:margin}:'',
      marginRight? { marginRight: marginRight } : '',
			
		]" :class="{fill,hidden,blur}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "div-card",
  props: {
    marginRight: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "3vw",
    },
    margin: {
      type: String,
      default: "",
    },
    src: {
      type: [String, null],
      default: "",
    },
    bg: {
      type: String,
      default: "",
    },
    bgcolor: {
      type: String,
      default: "",
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String,
      default: "2vw",
    },
    border: {
      type: String,
      default: "",
    },
    shadow: {
      type: [String, Boolean],
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    minWidth: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "",
    },
  },
}
</script>
<style lang='less' scoped>
.div-card {
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
}

.hidden {
  overflow: hidden;
}

.blur {
  backdrop-filter: blur(10px);
}

.fill {
  width: 100%;
}
</style>
