import axios from 'axios';
import router from '../router'
import qs from 'qs';
import vant from 'vant';
import { Toast, Lazyload } from 'vant';
import JSONBIG from 'json-bigint';
import VConsole from 'vconsole'
import plugins from '../plugins'
import regular from '../utils/regular'
import { i18n } from '../utils/lang'
export default {
  install: Vue => {
    Vue.use(vant)
    Vue.use(plugins)
    // const vConsole = new VConsole()
    // Vue.use(vConsole)
    Vue.prototype.$rg = regular //部分正则
    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = false
    // js返回进度丢失`
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({ storeAsString: true })
        const res = json.parse(data)
        return res
      }
    ]
    axios.defaults.timeout = 3000000; //请求超时
    const no_login_code = -1;       //未登录 状态码
    const success_code = 1;         //成功 状态码
    const err_code = 0;             //失败 状态码
    const token_headers = true      // true->token headers 传值

    if (process.env.NODE_ENV == 'development') setpath('http://ptdapp.php.qwangluo.net:2023')
    else if (process.env.NODE_ENV == 'production') setpath('')

    function setpath(api_path = '', web_path = api_path) {
      axios.defaults.baseURL = api_path;
      Vue.prototype.httpPath = web_path;
    }
    let lang = { zh: 'zh-cn', en: 'en-us', }
    Vue.prototype.$get = params => {
      const token = Vue.prototype.session.get("token") || "";
      let data
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        data = {
          params: Object.assign({}, params.data),
          headers: {
            'token': `${token}`,
            'locale': lang[Vue.prototype.local.get("lang") || 'zh']
          }
        }
      } else {
        data = {
          params: Object.assign({}, params.data, token ? { token } : '')
        }
      }
      axios.get(params.url, data).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.session.del("token");
          Toast({
            message: i18n.t('未登录'),
            onClose: () => {
              router.replace('/')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg || res.data.message);
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        err.response && Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }

    Vue.prototype.$post = params => {
      const token = Vue.prototype.session.get("token") || "";
      let data = {}
      let headers = {
        'Accept-Language': lang[Vue.prototype.local.get("lang") || 'zh']
      }
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        headers = {
          'token': `${token}`,
          'Accept-Language': lang[Vue.prototype.local.get("lang") || 'zh']
        }
        if (params.upload) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else if (params.noToken) {
          headers = {}
          // data = params.data  //有时候java传值方式
          data = qs.stringify(Object.assign({}, params.data))
        } else if (params.raw) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else {
          // data = params.data //有时候java传值方式
          data = qs.stringify(Object.assign({}, params.data))
        }
      } else {
        if (params.upload) {
          data = params.data.append('token', token ? token : "")
          headers['Content-Type'] = 'multipart/form-data'
        } else if (params.noToken) {
          data = qs.stringify(Object.assign({}, params.data))
        } else {
          data = qs.stringify(Object.assign({}, params.data, token ? { token } : {}))
        }
      }
      axios.post(params.url, data, { headers }).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.session.del("token");
          Toast({
            message: i18n.t('未登录'),
            onClose: () => {
              router.replace('/')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg || res.data.message);
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        err.response && Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }
  }
}