<template>
  <div-box>
    <nav-bar :title="$t('闪兑')" :leftArrow="true" :rightText="$t('闪兑记录')" @click-right="router('list', { type })" />
    <div-padding padding="4vw">
      <div-card bg='#fff' padding='4vw' margin="0 0 4vw 0">
        <div-text color='000' bold size='4vw' v-if="type == 1">{{ $t('闪兑金额(DOGE)') }}</div-text>
        <div-text color='000' bold size='4vw' v-if="type == 2">{{ $t('闪兑金额(GAN)') }}</div-text>

        <div-height height='4vw' />
        <div id="field">
          <div-input>
            <van-field :border='false' type="number" clearable v-model='price' @input="opt(price)"
              :formatter="formatter" :placeholder='`(` + pay_info.min_amount + `-` + pay_info.max_amount
                + `)`'>
              <template #button>
                <div-text size='5vw' bold color='#0E0E0E' v-if="type == 1">DOGE</div-text>
                <div-text size='5vw' bold color='#0E0E0E' v-if="type == 2">GAN</div-text>
              </template>
            </van-field>
          </div-input>
        </div>
        <van-divider :style="{ margin: '3vw 0 4vw' }" />
        <div-flex br>
          <div-text color='#999999' size='4vw' v-if="type == 1">{{ $t("可用余额") }} {{ info.balance }}</div-text>
          <div-text color='#999999' size='4vw' v-if="type == 2">{{ $t("可用余额") }} {{ info.coin }}</div-text>
          <div-text color='#999999' size='4vw' v-if="type == 1">{{ $t("手续费") }}{{ pay_info.fee }}%(DOGE)</div-text>
          <div-text color='#999999' size='4vw' v-if="type == 2">{{ $t("手续费") }}{{ pay_info.fee }}%(GAN)</div-text>

        </div-flex>

      </div-card>
      <div-card margin="0 0 4vw 0" bg='#fff' padding='3vw'>
        <div-flex left>
          <div-text color='#000' bold size='4vw'>{{ $t("实际数量") }}</div-text>
          <div-width width='6vw' />
          <div-input>
            <div-card padding='0vw' radius='2vw'>
              <van-field :border='false' readonly clearable v-model='new_num' :placeholder='$t("自动计算")'>
                <template #button>
                  <div-text size='4vw' color='#0E0E0E' v-if="type == 1">GAN</div-text>
                  <div-text size='4vw' color='#0E0E0E' v-if="type == 2">DOGE</div-text>
                </template>
              </van-field>
            </div-card>
          </div-input>
        </div-flex>
      </div-card>
      <div-card margin="0 0 4vw 0" bg='#fff' padding='3vw'>
        <div-text color='#000' bold size='4vw'>{{ $t("闪兑说明") }} </div-text>
        <div-html :html="pay_info.info"></div-html>
      </div-card>
    </div-padding>
    <div-foot padding="4vw 4vw 20vw 4vw">
      <div-btn ref="btn" radius="20vw" bg="#53B386" @click.native="confirm">
        <div-text color='#fff' bold size='4vw'>确认</div-text>
      </div-btn>
    </div-foot>
  </div-box>
</template>

<script>
  export default {
    data() {
      return {
        info: {},
        pay_info: {},
        price: "",
        new_price: "",
        loading: false,
        new_num: "",
        type: "", //1类型 1 DOGE兑换xc 2 xc兑换DOGE
      }
    },
    created() {
      this.type = this.$route.query.type
      this.get_info()
      this.getdata()
    },
    methods: {
      formatter(value) {
        // Remove any non-numeric characters
        value = value.replace(/[^0-9.]/g, "")
        // Limit to two decimal places
        const decimalIndex = value.indexOf(".")
        if (decimalIndex !== -1) {
          value = value.slice(0, decimalIndex + 5)
        }
        return value
      },
      confirm() {
        if (this.price == "") {
          this.$toast(this.$t("请输入闪兑金额"))
          return
        }
        if (this.loading == true) {
          return
        }
        this.loading = true
        this.$post({
          url: "/app/exchange/exchangeDo",
          data: {
            amount: this.price,
            type: this.type, //1类型 1 DOGE兑换xc 2 xc兑换DOGE
          },
          success: (res) => {
            this.price = ""
            this.$toast(this.$t("闪兑成功"))
            setTimeout(() => {
              this.loading = false
              //返回上一页
              this.$router.go(-1)
            }, 1000)
          },
          tip: () => {
            setTimeout(() => {
              this.get_info()
              this.loading = false
            }, 1000)
          },
        })
      },
      opt(price) {
        if (this.type == 1) {
          this.new_num = (
            (price - (price * this.pay_info.fee) / 100) /
            this.pay_info.xc_price
          ).toFixed(4)
        } else {
          this.new_num = (
            (price - (price * this.pay_info.fee) / 100) *
            this.pay_info.xc_price
          ).toFixed(4)
        }
      },
      getdata() {
        this.$post({
          url: "/app/exchange/getFee",
          success: (res) => {
            this.pay_info = res.data
          },
          tip: () => { },
        })
      },
      get_info() {
        this.$post({
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        })
      },
    },
  }
</script>

<style scoped lang="less">
  #field /deep/.van-field__control {
    font-size: 7vw !important;
    font-weight: 550 !important;
    color: #000 !important;
  }
</style>