import Vue from 'vue'
import App from './App'
import router from './router/index'
import 'vant/lib/index.css';
import './assets/css/reset.css';
import http from './request/http';

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;
Vue.use(http)
import { i18n } from './utils/lang'
import store from './store';
import Api from './ethChain'
import metaMask from './utils/metaMask'
import * as blockFun from './utils/chain/block'
Vue.use(Api)
Vue.use(metaMask)
Vue.prototype.blockFun = blockFun

Vue.config.productionTip = false;
new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
