<template>
  <div class="div-padding" :style="'padding:'+padding" :class="{fill}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    padding: {
      type: String,
      default: "0 3vw",
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang='less' scoped>
.div-padding {
  box-sizing: border-box;
}
.fill {
  width: 100%;
}
</style>
