<template>
  <div class="div-dad">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "div-dad",
  props: {},
}
</script>

<style lang='less' scoped>
.div-dad {
  position: relative;
  z-index: 1;
}
</style>
