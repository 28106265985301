<template>
  <div-box>
    <nav-bar :title="$t('兑换')" :leftArrow="true" />
    <div-padding padding="4vw">
      <div-card bg='#fff' padding='4vw' margin="0 0 4vw 0">
        <div-text color='000' bold size='4vw'>{{ $t('兑换金额(DOGE)') }}</div-text>
        <div-height height='4vw' />
        <div id="field">
          <div-input>
            <van-field :border='false' type="number" clearable v-model='price' @input="opt(price)"
              :formatter="formatter" :placeholder='`(` + pay_info.min_amount + `-` + pay_info.max_amount
                + `)`'>
              <template #button>
                <div-text size='5vw' bold color='#0E0E0E'>DOGE</div-text>
              </template>
            </van-field>
          </div-input>
        </div>
        <van-divider :style="{ margin: '3vw 0 4vw' }" />
        <div-flex br>
          <div-text color='#999999' size='4vw'>{{ $t('可用余额') }} {{ info.balance }}</div-text>
          <div-text color='#999999' size='4vw'>{{ $t('手续费') }}{{ pay_info.fee }}%</div-text>
        </div-flex>

      </div-card>
      <div-card margin="0 0 4vw 0" bg='#fff' padding='3vw'>
        <div-flex left>
          <div-text color='#000' bold size='4vw'>{{ $t('到账数量') }}</div-text>
          <div-width width='6vw' />
          <div-input>
            <div-card padding='0vw' radius='2vw'>
              <van-field :border='false' readonly clearable v-model='new_num'
                :placeholder='$t(`1DOGE兑换`) + `${pay_info.point_rate}` + $t(`个平台积分`)'>
                <template #button>
                  <div-text size='4vw' color='#0E0E0E'>积分</div-text>
                </template>
              </van-field>
            </div-card>
          </div-input>
        </div-flex>
      </div-card>
      <div-card margin="0 0 4vw 0" bg='#fff' padding='3vw'>
        <div-text color='#000' bold size='4vw'>兑换说明</div-text>
        <div-html :html="pay_info.info"></div-html>
      </div-card>

    </div-padding>

    <div-foot padding="4vw 4vw 20vw 4vw">
      <div-btn ref="btn" radius="20vw" bg="#53B386" @click.native="confirm">
        <div-text color='#fff' bold size='4vw'>确认</div-text>
      </div-btn>
    </div-foot>
  </div-box>
</template>

<script>
  export default {
    data() {
      return {
        info: {},
        pay_info: {},
        price: "",
        new_price: "",
        loading: false,
        new_num: "",
      }
    },
    created() {
      this.get_info()
      this.getdata()
    },
    methods: {
      formatter(value) {
        // Remove any non-numeric characters
        value = value.replace(/[^0-9.]/g, "")
        // Limit to two decimal places
        const decimalIndex = value.indexOf(".")
        if (decimalIndex !== -1) {
          value = value.slice(0, decimalIndex + 5)
        }
        return value
      },
      confirm() {
        if (this.price == "") {
          this.$toast(this.$t("请输入兑换数量"))
          return
        }
        if (this.loading == true) {
          return
        }
        this.loading = true
        this.$post({
          url: "/app/point_exchange/exchangeDo",
          data: {
            amount: this.price,
          },
          success: (res) => {
            this.price = ""
            this.$toast(this.$t("兑换成功"))
            setTimeout(() => {
              this.loading = false
              //返回上一页
              this.$router.go(-1)
            }, 1000)
          },
          tip: () => {
            setTimeout(() => {
              this.get_info()
              this.loading = false
            }, 1000)
          },
        })
      },
      opt(price) {
        this.new_num =
          price * this.pay_info.point_rate * 1 -
          (price * this.pay_info.point_rate * 1 * this.pay_info.fee) / 100
        this.new_num = this.new_num.toFixed(4)
        if (this.new_num == 0) {
          this.new_num = ""
        }
      },
      getdata() {
        this.$post({
          url: "/app/point_exchange/getFee",
          success: (res) => {
            this.pay_info = res.data
          },
          tip: () => { },
        })
      },
      get_info() {
        this.$post({
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        })
      },
    },
  }
</script>

<style scoped lang="less">
  #field /deep/.van-field__control {
    font-size: 7vw !important;
    font-weight: 550 !important;
    color: #000 !important;
  }
</style>