<template>
  <div-box>
    <nav-bar :title="$t('拼团')" :leftArrow="true" />
    <div-padding padding="4vw">
      <div-card bg='#fff' padding='4vw' margin="0 0 4vw 0">
        <div-flex>
          <div-text color='#08102C' bold size='4vw'>{{ $t('第') }}{{ info.period_no }}{{ $t('期') }}</div-text>
          <!-- <div-text color='#53B386' bold size='4vw'>进行中</div-text> -->
        </div-flex>
        <div-height height='3vw' />
        <div-flex>
          <div-width width='40vw'>
            <div-flex column>
              <div-text color='#EF4034' center bold size='5vw'>{{ info.has_amount }}</div-text>
              <div-text color='#898989' center size='3vw'>参与金额</div-text>
            </div-flex>
          </div-width>
          <div-width width='40vw'>
            <div-flex column>
              <div-text color='#EF4034' center bold size='5vw'>{{ info.remain_amount }}</div-text>
              <div-text color='#898989' center size='3vw'>剩余金额</div-text>
            </div-flex>
          </div-width>
        </div-flex>
      </div-card>
      <div-card bg='#fff' padding='4vw' margin="0 0 4vw 0">
        <div-text color='#333' bold size='4vw'>{{ $t('参与拼团价格(DOGE)') }}</div-text>
        <div-height height='4vw' />
        <div-input>
          <van-field :border='false' type="number" @input="opt(price)" :formatter="formatter" clearable v-model='price'
            :placeholder='`(` + info.min + `-` + info.max + `)`'>
            <template #button>
              <div-text size='5vw' bold color='#0E0E0E'>DOGE</div-text>
            </template>
          </van-field>
        </div-input>
        <van-divider :style="{ margin: '3vw 0 4vw' }" />
        <div-flex br>
          <div-text color='#999999' size='4vw'>{{ $t('可用余额') }} {{ member.balance }}DOGE</div-text>
          <div-text color='#999999' size='4vw'>{{ $t('手续费') }} {{ fee_price }}GAN</div-text>
        </div-flex>
      </div-card>
      <div-card bg='#fff' padding='4vw' margin="0 0 4vw 0">
        <div-text color='#333' bold size='4vw'>参与说明</div-text>
        <div-html :html="illustrate"></div-html>
      </div-card>
    </div-padding>
    <div-foot>
      <div-padding padding="11vw">
        <div-btn bg='#53B386' @click.native="determine">
          <div-text color='#fff' size='4vw'>立即参与</div-text>
        </div-btn>
      </div-padding>
    </div-foot>
  </div-box>
</template>

<script>
  export default {
    data() {
      return {
        price: "",
        info: {},
        member: {},
        illustrate: "",
        fee_price: 0,
        xc_price: 0,
      }
    },
    methods: {
      formatter(value) {
        // Remove any non-numeric characters
        value = value.replace(/[^0-9.]/g, "")

        // Limit to two decimal places
        const decimalIndex = value.indexOf(".")
        if (decimalIndex !== -1) {
          value = value.slice(0, decimalIndex + 5)
        }

        return value
      },
      opt(price) {
        this.fee_price = (
          (price * this.info.fee_rate) /
          100 /
          this.xc_price
        ).toFixed(4)
      },
      get_info() {
        this.$post({
          url: "/app/goods_period/periodInfo",
          data: {
            period_id: this.$route.query.id,
          },
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        })
        this.$post({
          url: "/app/game_order/gameInfo",
          success: (res) => {
            this.illustrate = res.data.info
            this.xc_price = res.data.xc_price
          },
          tip: () => { },
        })
      },
      determine() {
        if (!this.price) return this.$toast(this.$t("请输入参与金额"))
        if (this.info.min * 1 > this.price * 1)
          return this.$toast(this.$t("参与金额不能小于") + this.info.min)
        if (this.price * 1 > this.info.max * 1)
          return this.$toast(this.$t("参与金额不能大于") + this.info.max)

        this.$post({
          url: "/app/game_order/addOrder",
          data: {
            period_id: this.$route.query.id,
            amount: this.price,
          },
          success: (res) => {
            this.$toast(this.$t("参与成功"))
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          },
          tip: () => { },
        })
      },
      get_member() {
        this.$post({
          url: "/app/member/memberInfo",
          success: (res) => {
            this.member = res.data
          },
          tip: () => { },
        })
      },
    },
    created() {
      this.get_info()
      this.get_member()
    },
  }
</script>

<style scoped lang="less">
  /deep/.van-field__control {
    font-size: 7vw !important;
    font-weight: 550 !important;
    color: #000 !important;
  }
</style>