<template>
  <div class='div-popu'>
    <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
.div-popu {
  /deep/.van-dialog {
    background: transparent !important;
  }
}
</style>
