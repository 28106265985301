<template>
  <div class="div-flex" @click="$emit('click')" :style="[margin?{margin:margin}:'',
	 height?{height}:'',
	 width?{width}:'',
	 ]" :class="{left,right,init,center,bottom,baseline,top,br,fill,column,shrink}">
    <slot></slot>
  </div>

</template>
<script>
export default {
  name: "div-flex",
  props: {
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    margin: {
      type: String,
      default: "",
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },

    center: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    baseline: {
      type: Boolean,
      default: false,
    },
    init: {
      type: Boolean,
      default: false,
    },
    br: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Boolean,
      default: false,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang='less' scoped>
.div-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.column {
  flex-direction: column;
  align-items: initial;
}

.init {
  align-items: initial;
}

.bottom {
  align-items: flex-end;
}

.baseline {
  align-items: baseline;
}

.top {
  align-items: flex-start;
}

.br {
  flex-wrap: wrap;
}

.fill {
  width: 100%;
}

.shrink {
  flex-shrink: 0;
}
</style>
