<template>
  <div-box bg="#fff">
    <nav-bar :title="$t('制度说明')" :leftArrow="true" />
    <div-padding padding="4vw">

      <div-html :html="info" />
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      info: "",
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$post({
        url: "/app/member/getZhidu",
        success: (res) => {
          this.info = res.data.info
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style>
</style>