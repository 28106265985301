var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-top-box",style:(`height:${_vm.height}`)},[_c('div',{staticClass:"div-top",class:{tabBar: _vm.tabBar},style:([
    _vm.top?{top:_vm.top}:'',
		_vm.radius?{borderRadius:_vm.radius}:'',
		_vm.bg?{background:_vm.bg}:'',
		_vm.padding?{padding:_vm.padding}:'',
		_vm.shadow?{boxShadow:'0px 1vw 3vw '+_vm.shadow}:'',
	])},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }