import { render, staticRenderFns } from "./mother_coin_s.vue?vue&type=template&id=e49f3b80&scoped=true"
import script from "./mother_coin_s.vue?vue&type=script&lang=js"
export * from "./mother_coin_s.vue?vue&type=script&lang=js"
import style0 from "./mother_coin_s.vue?vue&type=style&index=0&id=e49f3b80&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e49f3b80",
  null
  
)

export default component.exports