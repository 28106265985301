<template>
  <div class='div-notice' :class="tab">
    <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    tab: {
      type: String,
      default: "stick",
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
.div-notice {
  display: flex;
  align-items: center;
  /deep/.van-swipe {
    height: 12vw !important;
    width: 100%;
    .van-swipe-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      .div-text {
        line-height: 5.5vw;
        width: 100%;
      }
    }
  }
}
</style>
