<template>
  <div-box>
    <nav-bar :title="$t('我的拼团记录')" :leftArrow="true" />
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :noToken="false" url="/app/game_order/orderList" :data="{}">
      <div-padding padding="4vw">
        <div-card bg='#fff' margin="0 0 4vw 0" padding='3vw' v-for="(item,index) in list" :key="index">
          <div-text color='#08102C' bold size='4vw'>{{item.goods_name}} · {{$t('第')}}{{item.period_no}}{{$t('期')}}</div-text>
          <div-height height='3vw' />
          <div-flex>
            <div-width width='40vw'>
              <div-flex column>
                <div-text color='#EF4034' center bold size='5vw'>{{item.period_amount}}</div-text>
                <div-text color='#898989' center size='3vw'>期次金额</div-text>
              </div-flex>
            </div-width>
            <div-width width='40vw'>
              <div-flex column>
                <div-text color='#EF4034' center bold size='5vw'>{{item.amount}}</div-text>
                <div-text color='#898989' center size='3vw'>参与金额</div-text>
              </div-flex>
            </div-width>
          </div-flex>
          <van-divider dashed :style="{margin:'4vw 0'}" />
          <div-card margin="0 0 3vw 0" bg='#F3F5FC' padding='0vw'>
            <div-card radius="3vw 0 3vw 0" width="24vw" bg='#53B386' padding='0.5vw 2vw'>
              <div-text color='#fff' center size='3vw'>参与人数</div-text>
            </div-card>
            <div-padding padding="2vw 5vw 4vw 5vw">
              <div-flex>
                <div-flex column>
                  <div-text color='#0F0F0E' bold size='4vw'>当前参与人数</div-text>
                  <div-text color='#53B386' bold size='4vw'>{{item.join_num}}</div-text>
                </div-flex>
                <div-card bg='#53B386' radius="50vw" padding='2vw 4vw' v-if="item.status==1">
                  <div-text color='#fff' size='4vw'>进行中</div-text>
                </div-card>
                <div-card bg='#53B386' radius="50vw" padding='2vw 4vw' v-if="item.status==2">
                  <div-text color='#fff' size='4vw'>拼团成功</div-text>
                </div-card>
                <div-card bg='#DBDBDB' radius="50vw" padding='2vw 4vw' v-if="item.status==3">
                  <div-text color='#8A8A8A' size='4vw'>拼团失败</div-text>
                </div-card>
              </div-flex>
            </div-padding>
          </div-card>
          <div-card bg='#FFF8E9' padding='1vw 3vw'>
            <div-flex left>
              <div-img width='3vw' height='3vw'>
                <img src='~@/assets/imgs/icon_62@2x.png' />
              </div-img>
              <div-width width='2vw' />
              <div-text color='#FF5E10' size='3vw'>{{item.create_time}}</div-text>
            </div-flex>
          </div-card>

        </div-card>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.up_down()
  },
  methods: {
    // 立即参与
    join() {
      this.$router.push({ path: "/spell/spell_detail" })
    },
  },
  mounted() {},
}
</script>


<style>
</style>