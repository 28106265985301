<template>
  <div-box>
    <nav-bar :title="$t('互转记录')" :leftArrow="true" />
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :noToken="false"
      url="/app/transfer/transferList" :data="{ balance_type: type }">
      <div-padding padding="4vw">
        <div-card radius="3vw 3vw 0 0" bg='#fff' padding='4vw 0 1vw'>
          <div-flex left>
            <div-card margin="0 3vw 0 0" radius="0 3vw 3vw 0" bg='#53B386' width="1vw" padding='2.5vw 0vw'>
            </div-card>
            <div-text color='#000' bold size='4vw' v-if="type == 1">{{ $t('互转记录') }}(DOGE)</div-text>
            <div-text color='#000' bold size='4vw' v-if="type == 3">{{ $t('互转记录') }}(GAN)</div-text>
          </div-flex>
        </div-card>
        <div-card bg='#fff' radius="0" padding='3vw 4vw 0 4vw' v-for="(item, index) in list" :key="index">
          <div-flex left>
            <div-text color='#000' bold size='4vw'>转入地址：{{ addressFun(item.to_phone) }}</div-text>
            <div-width width='2vw' />
            <div-img width='3vw' height='3vw' @click.native="copyData(item.to_phone)">
              <img src='~@/assets/imgs/copy@2x.png' />
            </div-img>
          </div-flex>

          <div-height height='1vw' />
          <div-flex>
            <div-width width='50vw'>
              <div-flex width='100%' column height="10vw">
                <div-text color='#8A8A8A' size='3.5vw'>{{ $t('手续费') }}：{{ item.fee }}</div-text>
                <div-text color='#8A8A8A' size='3vw'>{{ item.create_time }}</div-text>
              </div-flex>
            </div-width>
            <div-text color='#000' bold size='5vw'>-{{ item.amount }}</div-text>
          </div-flex>
          <div-height height='3vw' />
          <van-divider :style="{ margin: '0 0' }" />
        </div-card>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>

<script>
  export default {
    data() {
      return {
        list: [],
        type: "",
      }
    },
    methods: {},
    created() {
      this.type = this.$route.query.type
      this.up_down()
    },
  }
</script>

<style></style>